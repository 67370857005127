import { Button, Modal } from "react-bootstrap";
import { SurveyQrCode } from "../survey-qr-code/survey-qr-code";

export interface IQRCodeModalProps {
    show: boolean;
    surveyId: string;
    ambassadorId: string;
    onHide: () => void;
    onAccept: () => void;
}

export const QRCodeModal = (props: IQRCodeModalProps) => {
    return (
    <Modal className='confirm-delete-question-modal' centered show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton>
            <Modal.Title>QR Code</Modal.Title>
        </Modal.Header>

        <Modal.Body className="confirm-delete-question-modal-body">
            <div>
            Escanea este QR code para tomar la encuesta
            </div><br/>
            <SurveyQrCode surveyId={props.surveyId} ambassadorId={props.ambassadorId}></SurveyQrCode>
        </Modal.Body>

        <Modal.Footer className='confirm-delete-question-modal-footer'>
            <Button  onClick={() => { 
                props.onAccept();
                }}>Cerrar</Button>
        </Modal.Footer>
    </Modal>
    );
}