import QRCode from 'qrcode.react';

export interface IQrCodeProps {
    surveyId: string;
    ambassadorId: string;
}

export const SurveyQrCode = (props: IQrCodeProps) => {
    const url = `https://encuestas.store/survey/${props.surveyId}?ambassadorId=${props.ambassadorId}`;
    return (
        <div>
        <QRCode
            value={url}
            size={200}
            bgColor={"#ffffff"}
            fgColor={"#000000"}
            level={"L"}
            includeMargin={false}
            renderAs={"canvas"}
            imageSettings={{
                src: "/favicon2.png",
                x: null,
                y: null,
                height: 45,
                width: 45,
                excavate: false,
            }}></QRCode>
            <br/><br/>
            <a href={url} target="_blank">{url}</a>
        </div>
    );
};