
import './privacy-mx-page.scss';

export const PrivacyMXPage = () => {


    return (
        <div className="privacy-mx-page">
          <div><b>AVISO DE PRIVACIDAD</b></div><br/>

En cumplimiento con lo establecido por la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, le informamos nuestra política de privacidad y manejo de datos personales.
<br/><br/>
Encuestas Store es una empresa registrada con domicilio establecido en el Estado de México, que utiliza el sitio web https://encuestas.store/.
<br/><br/>
Por lo que se establecen diferentes áreas concernientes a la privacidad del usuario, así como obligaciones y requerimientos; además, de la forma en que este sitio web procesa, almacena y protege los datos de usuarios, como son nombre, edad, lugar donde se radica y/o afinidad política.
<br/><br/>
La información personal puede ser recabada para:
<br/><br/>
<ul>
    <li>Cuando un usuario se registra para hacer uso de nuestros servicios en este sitio web, o a través de sitio web de terceros asociados.</li>
    <li>Se nos contacta o nos envían sus comentarios. Podemos pedirle que nos proporcione más información cuando se notifica un problema con nuestro sitio web.</li>
    <li>Elaborar Contratos.</li>
    <li>Realizar facturación y cobranza.</li>
    <li>Identificar, ubicar y comunicarse con el usuario para envió de publicidad y/o promociones.</li>
    <li>Llevar un registro de usuarios.</li>
    <li>Cumplir con todas las obligaciones y/o disposiciones legales.</li>
    <li>Realizar encuestas respecto de la percepción o prestación de diferentes servicios.</li>
    <li>Estadísticas internas.</li>
</ul>

Para el caso de que el Titular no desee que sus datos personales sean tratados para alguna o todas las finalidades mencionadas debe de enviar una solicitud al correo electrónico a encuestasstore@gmail.com, mediante un escrito libre, indicando los motivos y/o circunstancias de su solicitud.
<br/><br/>
Encuestas Store podrá transferir los datos personales de los usuarios siempre y cuando cuente con el consentimiento de éstos, salvo lo establecido en los artículos 36 y 37 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares o cuando dicha transferencia sea necesaria para asegurar el cumplimiento de disposiciones y/u obligaciones legales.
<br/><br/>
No será necesario el consentimiento para el tratamiento de datos personales de los usuarios, cuando se encuentre en algunos de los supuestos previstos en el artículo 10 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares
<br/><br/>
El usuario tiene derecho a saber para que se utilizan sus datos y las condiciones del uso, a solicitar la corrección de su información personal, a que se eliminen sus datos proporcionados y oponerse al uso de sus datos, estos derechos se conocen como Derechos ARCO.
<br/><br/>
Para ejercer el citado derecho el usuario podrá enviar su solicitud a Encuestas Store por medio de correo electrónico a encuestasstore@gmail.com, mediante un escrito libre, indicando los motivos y/o circunstancias de su solicitud.
<br/><br/>
Esta Política de Privacidad se encuentra bajo revisión periódica, por lo que Encuestas Store se reserva el derecho de cambiar el presente aviso en cualquier momento, notificando por medio del sitio web de los cambios que en su caso se realicen. En ese sentido si usted no manifiesta su oposición al presente aviso de privacidad, se entenderá que ha otorgado su consentimiento tácito para ello.

<br/><br/>
El presente aviso de privacidad sufrió modificaciones el 04 de abril del 2023.


        </div>
    );
};
