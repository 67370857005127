import { Form } from "react-bootstrap";
import { IQuestion } from "../../models/IQuestion";

export interface ISurveyQuestionProps {
    question: IQuestion
    onAnswerChanged: (config: {
        type: string,
        isSet: boolean,
        questionId: string, 
        subquestionId: string, 
        answerId?: string,
        text?: string
    }) => void
}

export const SurveyQuestion = (props: ISurveyQuestionProps) => {
    const question = props.question;
    const isSingleChoice = question.answerType === "singlechoice";
    const isMultipleChoice = question.answerType === "multiplechoice";
    const isNumberRange = question.answerType === "numberrange";
    const isTable = question.answerType === "table";
    const isText = question.answerType === "text";
    const isDropdown = question.answerType === "dropdown";
    var results: {questionId: string, answerId: string}[] = [];

    var rangeOptions: string[] = [];
    if (isNumberRange) {
        for (var i = question.rangeStart || 0; i < (question.rangeEnd || 0); i++) {
            rangeOptions.push(i.toString());
        }
    }

    return (
        <>
            <div>{question.question}</div>
            {
                isSingleChoice ?
                <div>
                    {question.answers?.map((entry, index) => (
                        <Form.Check
                            onChange={(e) => {
                                props.onAnswerChanged({
                                    type: question.answerType,
                                    isSet: true,
                                    questionId: question.id,
                                    subquestionId: '',
                                    answerId: e.target.value
                                });
                            }}
                            type="radio"
                            label={entry}
                            value={question.answersId?.[index]}
                            name={`radio${question.id}`}
                            id={`radio${question.id}${index}`}
                        /> 
                    ))}
                </div>
                : <></>
            }
            {
                isMultipleChoice ?
                <div>
                    {question.answers?.map((entry, index) => (
                        <Form.Check
                            type="checkbox"
                            label={entry}
                            onChange={(e) => {
                                props.onAnswerChanged({
                                    type: question.answerType,
                                    isSet: e.target.checked,
                                    questionId: question.id,
                                    subquestionId: '',
                                    answerId: e.target.value
                                });
                            }}
                            value={question.answersId?.[index]}
                            name={`check${question.id}`}
                            id={`check${question.id}${index}`}
                        /> 
                    ))}
                </div>
                : <></>
            }
            {
                isNumberRange ?
                <div>
                    <select aria-label="Default select example">
                        <option value="-1">Selecciona</option>
                    {rangeOptions.map((entry, index) => (
                        <option value="1">{entry}</option>
                    ))}
                    </select>
                </div>
                : <></>
            }
            {
                isText ?
                <div>
                    <textarea  
                        style={{width:'90%', marginTop:10}}
                        rows={3}
                        onChange={(e) =>{ 
                            props.onAnswerChanged({
                                type: question.answerType,
                                isSet: true,
                                questionId: question.id,
                                subquestionId: '',
                                text: e.target.value
                            });
                        }} 
                        onBlur={(e) => {  
                            props.onAnswerChanged({
                                type: question.answerType,
                                isSet: true,
                                questionId: question.id,
                                subquestionId: '',
                                text: e.target.value
                            });
                        } }
                    ></textarea>
                </div> : <></>
            }
            {
                isDropdown ?
                <div>
                    <select  
                        style={{marginTop:10}}
                        
                        onChange={(e) =>{ 
                            props.onAnswerChanged({
                                type: question.answerType,
                                isSet: true,
                                questionId: question.id,
                                subquestionId: '',
                                answerId: e.target.value
                            });
                        }} 
                    >
                        <option>Selecciona</option>
                        {question.answers?.map((entry, index) => (
                            <option value={question.answersId?.[index]}>{entry}</option>
                        ))}
                    </select>
                </div> : <></>
            }
            {
                isTable ?
                <div>
                    <table className="survey-table">
                        <tr>
                            <th></th>
                            {question.answers?.map((entry, index) => (
                                <th>{entry}</th>
                            ))}
                        </tr>
                        {question.rows?.map((entry, questionIndex) => (
                        <tr>
                            <td style={{paddingLeft:4}}>{entry}</td>
                            {question.answers?.map((entry, answerIndex) => (
                            <td>
                            <Form.Check
                                type="radio"
                                label={''}
                                onChange={(e) => {
                                    props.onAnswerChanged({
                                        type: question.answerType,
                                        isSet: true,
                                        questionId: question.id,
                                        subquestionId: question.rowsId?.[questionIndex] || '',
                                        answerId: e.target.value
                                    });
                                }}
                                value={question.answersId?.[answerIndex]}
                                name={`radio${question.id}-${questionIndex}`}
                                id={`radio${question.id}${answerIndex}`}
                            /> 
                            </td>
                            ))}
                        </tr>
                        ))}
                    </table>
                </div>
                : <></>
            }
            <br/>
        </>
    );
}