import { i18n } from '@lingui/core';
import moment from 'moment';
import {
  en,
  es,
  pt,
} from 'make-plural/plurals';
import { isEmbedMode, isEmbedModeDogfood } from '../utils/navigator-util';

const catalogEn = require('../locales/en-US/messages');
const catalogEsCo = require('../locales/es-CO/messages');;
const catalogPtBR = require('../locales/pt-BR/messages');

export const defaultLocale = 'en-CO';

export const locales = [
  {
    locale: 'en-US',
    label: 'English',
  },
  {
    locale: 'es-CO',
    label: 'Español',
  },
  {
    locale: 'pt-BR',
    label: 'Português',
  }
];

i18n.loadLocaleData('en-US', { plurals: en });
i18n.loadLocaleData('es-CO', { plurals: es });
i18n.loadLocaleData('pt-BR', { plurals: pt });
i18n.load('en-US', catalogEn.messages);
i18n.load('es-CO', catalogEsCo.messages);
i18n.load('pt-BR', catalogPtBR.messages);

export function activateLocale(locale: string) {
  i18n.activate(locale);
  moment.locale([locale, 'es']);
}

export { i18n };

export const getBrowserLanguage = () => {
  try {
    if (isEmbedMode()) {
      return;
    }
    var browserLanguage = navigator.language;
    for (var loc of locales) {
      var localeLangOnly = loc.locale.split('-')[0];
      var browserLocaleLangOnly = browserLanguage.split('-')[0];
      if (localeLangOnly === browserLocaleLangOnly || loc.locale.toLowerCase() === browserLanguage.toLowerCase()) {
        return loc.locale;
      }
    }
  } catch(e) {
    console.log(e);
  }
};

let locale = window.localStorage['locale'] || getBrowserLanguage() || defaultLocale;
if (locale && locale.length === 2) {
  locale = defaultLocale;
}

activateLocale(locale);