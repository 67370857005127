import { Button, Modal } from "react-bootstrap";
import { ISurvey } from "../../models/ISurvey";

export interface IConfirmDeleteSurveyModalProps {
    show: boolean;
    survey?: ISurvey;
    onHide: () => void;
    onAccept: () => void;
}

export const ConfirmDeleteSurveyModal = (props: IConfirmDeleteSurveyModalProps) => {
    return (
    <Modal className='confirm-delete-survey-modal' centered show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton>
            <Modal.Title>Eliminar Encuesta</Modal.Title>
        </Modal.Header>

        <Modal.Body className="confirm-delete-survey-modal-body">
            <div>
            Estas seguro que quieres borrar esta encuesta y todas sus respuestas?
            </div>
            <div>{props.survey?.name}</div>
        </Modal.Body>

        <Modal.Footer className='confirm-delete-survey-modal-footer'>
            <Button  onClick={() => { 
                props.onAccept();
                }}>Eliminar</Button>
        </Modal.Footer>
    </Modal>
    );
}