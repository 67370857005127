import { stringify } from 'querystring';
import React ,{useState} from 'react';
import { getAPIBaseUrl } from '../services/api';
import { getCookie } from './cookie-manager';

 const UploadImageToS3 = (props: { onSuccess: (url)=>void}) => {

     const [selectedFile, setSelectedFile] = useState(null);
     const [status, setStatus] = useState('');

     const handleFileInput = (e) => setSelectedFile(e.target.files[0]);

     const uploadFile = (file) => {

         var fileName = new Date().getTime() +file.name;
         setStatus('Subiendo...');
         fetch(`${getAPIBaseUrl()}/api/getSignedUrl`,
         {
             method:'POST' ,
             headers: {
                 "Content-Type": "application/json",
                 "canary": getCookie("canary") || "",
                 "user-id": getCookie("user-id") || "",
             },
             body:JSON.stringify({
                 fileName: fileName ,
                 fileType: file.type
             })
         })
             .then(async res => {
             var url = await res.json();
             if (url.url) {
                 fetch(url.url, {
                     method:'PUT',
                     body :file
                 }).then((res) => {
                     setStatus('');
                     props.onSuccess(`https://s3.us-east-2.amazonaws.com/logos.encuestas.store/${fileName}`);
                 })
             } else {
                 setStatus('');
                 alert("No pudimos subir la imagen. Error: " + url.error);
                 console.log(url.error);
             }
         })

     };


     return <>
         <input type="file" onChange={handleFileInput}/>
         <a href="#" onClick={() => uploadFile(selectedFile)}>Subir Imagen</a><br/>
         <div>{status}</div>
         <br/>
     </>
 }

 export default UploadImageToS3; 