import { Button, Modal } from "react-bootstrap";

export interface IConfirmDeleteUserModalProps {
    show: boolean;
    username: string;
    onHide: () => void;
    onAccept: () => void;
}

export const ConfirmDeleteUserModal = (props: IConfirmDeleteUserModalProps) => {
    return (
    <Modal className='confirm-delete-user-modal' centered show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton>
            <Modal.Title>Eliminar usuario</Modal.Title>
        </Modal.Header>

        <Modal.Body className="confirm-delete-user-modal-body">
            <div>
            Estas seguro que quieres eliminar este usuario:
            </div>
            <span>{props.username}</span><span>?</span>
        </Modal.Body>

        <Modal.Footer className='confirm-delete-user-modal-footer'>
            <Button  onClick={() => { 
                props.onAccept();
                }}>Eliminar</Button>
        </Modal.Footer>
    </Modal>
    );
}