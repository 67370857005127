import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import { ActionsType } from "./store";


export interface TopLevelState {
    darkMode: boolean;
}

export const topLevelReducer
    = createReducer<TopLevelState, ActionsType>({
        darkMode: false,
    });
        // .handleAction(actions.setDarkModeAction, (state, action) => ({
        //     ...state,
        //     darkMode: action.payload.darkMode,
        // }));

export type RootState = {
   
};

const reducers = combineReducers({
   
});

export default reducers;