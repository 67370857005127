import { t, Trans } from '@lingui/macro';
import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from "react-router-dom";
import { activateLocale, locales } from '../../config/locales';
import useLocale from '../../hooks/useLocale';
import LogoImg from '../../images/logo-tall.png';
import { getCookie, setCookie } from '../../utils/cookie-manager';
import { isLoggedIn, logOut } from '../../utils/login-util';
import { isAdmin } from '../../utils/ref-util';
// import { RootState } from '../../index.reducers';
import './top-bar.scss';

export const TopBar = () => {
    const history = useHistory();
    const [locale, setLocale] = useLocale();
    const dispatch = useDispatch();

    const darkMode = false;

    var getLocaleLabel = (locale: string): string => {
        var localeLabel = '';
        for (let entry of locales) {
            if (entry.locale === locale) {
                localeLabel = entry.label;
                break;
            }
        }
        return localeLabel;
    };
    const [localeLabel, setLocaleLabel] = React.useState(getLocaleLabel(locale));

    var onLanguageClick = (ev: any) => {
        var newLocale = ev.target.dataset.locale;
        var newLabel = getLocaleLabel(newLocale);
        setLocale(newLocale);
        setLocaleLabel(newLabel);
        activateLocale(newLocale);
        ev.preventDefault();
    };

    return (
        <Navbar collapseOnSelect expand="lg" bg={darkMode ? "dark" : ""} sticky="top" >
            <Navbar.Brand> <Link to="/">
                <img
                    src={LogoImg}
                    height="70"
                    className="d-inline-block align-top"
                    alt="Encuestas Store"
                /></Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                {isLoggedIn() ? <Nav.Link><Link to="/"><Trans>Inicio</Trans></Link></Nav.Link> : <></>}
                {/* <Nav.Link><Link to="/add-survey"><Trans>Add Survey</Trans></Link></Nav.Link>
                <Nav.Link><Link to="/add-survey-questions"><Trans>Add Survey Questions</Trans></Link></Nav.Link> */}
                <Nav className="mr-auto">
                </Nav>
                <Nav>
                    {/* <NavDropdown title={localeLabel} id="collasible-nav-dropdown">
                        {locales && locales.map((entry, i) =>
                            <NavDropdown.Item key={entry.locale} href={document.location.href} data-locale={entry.locale} onClick={onLanguageClick}>{entry.label}</NavDropdown.Item>
                        )}
                        <NavDropdown.Item key="contribute" href="https://crowdin.com/project/encuestasstore"><Trans>Ayuda a traducir</Trans></NavDropdown.Item>
                    </NavDropdown> */}
                    {isAdmin() ? <Nav.Link><Link to="/users"><Trans>Usuarios</Trans></Link></Nav.Link> : <></>}
                    {isLoggedIn() ? <Nav.Link><Link to="/"
                        onClick={ () => { logOut()} }
                    ><Trans>Cerrar sesión ({getCookie("username")})</Trans></Link></Nav.Link> : <></>}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};