import { IAnswer } from "../models/IAnswer";
import { IRawAnswer } from "../models/IRawAnswer";
import { IRawQuestion } from "../models/IRawQuestion";
import { IRespondent } from "../models/IRespondent";
import { ISurvey } from "../models/ISurvey";
import { IUser } from "../models/IUser";
import { getCookie } from "../utils/cookie-manager";

export const getAPIBaseUrl = (): string => {
  if (document.location.hostname === 'localhost') {
      return 'http://localhost:8090';
  } else if (document.location.hostname.indexOf('beta') === 0) {
      return "https://encuestas.store";
  } else {
      return '';
  }
}

export const login = (username: string, password: string): Promise<{code: number, userId: number, token: string, ref: number}> => {
  return fetch(
      `${getAPIBaseUrl()}/api/login`,
      {
          method: 'POST',
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            username,
            password,
          }),
      }
  ).then((response) => response.json());
};

export const submitSurvey = (surveyId: string, ambassadorId: string, respondentId: string, answers: IAnswer[]): Promise<{results: string[], respondentId: number}> => {
  return fetch(
      `${getAPIBaseUrl()}/api/surveys/${surveyId}`,
      {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "canary": getCookie("canary") || "",
            "user-id": getCookie("user-id") || "",
            "public": "1"
          },
          body: JSON.stringify({
            surveyId,
            respondentId,
            answers,
            ambassadorId
          }),
      }
  ).then((response) => response.json());
};

export const createSurvey = (survey: ISurvey): Promise<{ code: string, surveyId: number}> => {
  return fetch(
      `${getAPIBaseUrl()}/api/surveys`,
      {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "canary": getCookie("canary") || "",
            "user-id": getCookie("user-id") || ""
          },
          body: JSON.stringify(survey),
      }
  ).then((response) => response.json());
};

export const updateSurvey = (survey: ISurvey): Promise<string> => {
  return fetch(
      `${getAPIBaseUrl()}/api/surveys/${survey.id}/edit`,
      {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "canary": getCookie("canary") || "",
            "user-id": getCookie("user-id") || ""
          },
          body: JSON.stringify(survey),
      }
  ).then((response) => response.json());
};


export const updateRespondent = (respondent: IRespondent): Promise<string> => {
  return fetch(
      `${getAPIBaseUrl()}/api/respondent/${respondent.id}/edit`,
      {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "public": "1"
          },
          body: JSON.stringify(respondent),
      }
  ).then((response) => response.json());
};


export const addSurveyQuestion = (question: IRawQuestion): Promise<string> => {
  return fetch(
      `${getAPIBaseUrl()}/api/surveys/:id/questions/add`,
      {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "canary": getCookie("canary") || "",
            "user-id": getCookie("user-id") || ""
          },
          body: JSON.stringify(question),
      }
  ).then((response) => response.json());
};

export const addSurveyAmbassador = (surveyId: string, ambassadorId: string): Promise<string> => {
  return fetch(
      `${getAPIBaseUrl()}/api/surveys/${surveyId}/ambassadors/add`,
      {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "canary": getCookie("canary") || "",
            "user-id": getCookie("user-id") || ""
          },
          body: JSON.stringify({surveyId, ambassadorId}),
      }
  ).then((response) => response.json());
};

export const addSurveySupervisor = (surveyId: string, supervisorId: string): Promise<string> => {
  return fetch(
      `${getAPIBaseUrl()}/api/surveys/${surveyId}/supervisors/add`,
      {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "canary": getCookie("canary") || "",
            "user-id": getCookie("user-id") || ""
          },
          body: JSON.stringify({surveyId, supervisorId}),
      }
  ).then((response) => response.json());
};

export const removeSurveyQuestion = (questionId: string): Promise<string> => {
  return fetch(
      `${getAPIBaseUrl()}/api/surveys/questions/${questionId}/remove`,
      {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "canary": getCookie("canary") || "",
            "user-id": getCookie("user-id") || ""
          }
      }
  ).then((response) => response.json());
};

export const deleteAnswers = (surveyId: string, phone: string): Promise<string> => {
  return fetch(
      `${getAPIBaseUrl()}/api/surveys/${surveyId}/answers/delete`,
      {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "canary": getCookie("canary") || "",
            "user-id": getCookie("user-id") || ""
          },
          body: JSON.stringify({phone}),
      }
  ).then((response) => response.json());
};

export const removeSurveyAmbassador = (id: string): Promise<string> => {
  return fetch(
      `${getAPIBaseUrl()}/api/surveys/surveyambassador/${id}/remove`,
      {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "canary": getCookie("canary") || "",
            "user-id": getCookie("user-id") || ""
          }
      }
  ).then((response) => response.json());
};

export const removeSurveySupervisor = (id: string): Promise<string> => {
  return fetch(
      `${getAPIBaseUrl()}/api/surveys/surveysupervisor/${id}/remove`,
      {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "canary": getCookie("canary") || "",
            "user-id": getCookie("user-id") || ""
          }
      }
  ).then((response) => response.json());
};


export const insertUser = (option: IUser): Promise<string> => {
return fetch(
    `${getAPIBaseUrl()}/api/users/add`,
    {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "canary": getCookie("canary") || "",
          "user-id": getCookie("user-id") || ""
        },
        body: JSON.stringify(option),
    }
).then((response) => response.json());
};

export const insertSurveyQuestionAnswerOption = (option: {
    questionId: string;
    surveyId: string;
    answer: string;
  }): Promise<string> => {
  return fetch(
      `${getAPIBaseUrl()}/api/surveys/questions/${option.questionId}/options/add`,
      {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "canary": getCookie("canary") || "",
            "user-id": getCookie("user-id") || ""
          },
          body: JSON.stringify(option),
      }
  ).then((response) => response.json());
};

export const insertSurveyQuestionSubquestion = (option: {
  questionId: string;
  surveyId: string;
  text: string;
}): Promise<string> => {
  return fetch(
      `${getAPIBaseUrl()}/api/surveys/questions/${option.questionId}/subquestion/add`,
      {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "canary": getCookie("canary") || "",
            "user-id": getCookie("user-id") || ""
          },
          body: JSON.stringify(option),
      }
  ).then((response) => response.json());
};

export const removeSurveyQuestionAnswerOption = (optionId: string): Promise<string> => {
  return fetch(
      `${getAPIBaseUrl()}/api/surveys/questions/options/${optionId}/remove`,
      {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "canary": getCookie("canary") || "",
            "user-id": getCookie("user-id") || ""
          }
      }
  ).then((response) => response.json());
};

export const removeSurveyQuestionSubquestion = (id: string): Promise<string> => {
  return fetch(
      `${getAPIBaseUrl()}/api/surveys/questions/subquestion/${id}/remove`,
      {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "canary": getCookie("canary") || "",
            "user-id": getCookie("user-id") || ""
          }
      }
  ).then((response) => response.json());
};

export const verify = (phone: string, surveyId: string, ambassadorId: string, verifyCode?: string): Promise<{code: number, respondentId: string}> => {
  return fetch(
      `${getAPIBaseUrl()}/api/verify`,
      {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "canary": getCookie("canary") || "",
            "user-id": getCookie("user-id") || "",
            "public": "1"
          },
          body: JSON.stringify({
            phone,
            ambassadorId,
            surveyId,
            verifyCode
          }),
      }
  ).then((response) => response.json());
};

export const deleteSurvey = (surveyId: string): Promise<{code: number}> => {
  return fetch(
    `${getAPIBaseUrl()}/api/surveys/${surveyId}`,
    {
      method: 'DELETE',
      headers: {
        "canary": getCookie("canary") || "",
        "user-id": getCookie("user-id") || ""
      }
    }
  ).then((response) => response.json());
};

export const deleteUser = (user: IUser): Promise<{code: string}> => {
  return fetch(
    `${getAPIBaseUrl()}/api/users/${user.id || 0}`,
    {
      method: 'DELETE',
      headers: {
        "canary": getCookie("canary") || "",
        "user-id": getCookie("user-id") || ""
      }
    }
  ).then((response) => response.json());
};

export const getSurvey = (surveyId: string): Promise<ISurvey[]> => {
  return fetch(
    `${getAPIBaseUrl()}/api/surveys/${surveyId}`,
    {
      headers: {
        "canary": getCookie("canary") || "",
        "user-id": getCookie("user-id") || "",
        "public": "1"
      }
    }
  ).then((response) => response.json());
};

export const getSurveys = (): Promise<ISurvey[]> => {
  return fetch(
    `${getAPIBaseUrl()}/api/surveys/`,
    {
      headers: {
        "canary": getCookie("canary") || "",
        "user-id": getCookie("user-id") || ""
      }
    }
  ).then((response) => response.json());
};

export const getUsers = (scope: string): Promise<IUser[]> => {
  return fetch(
    `${getAPIBaseUrl()}/api/users/${scope}`,
    {
      headers: {
        "canary": getCookie("canary") || "",
        "user-id": getCookie("user-id") || ""
      }
    }
  ).then((response) => response.json());
};

export const getSurveyRespondents = (surveyId: string): Promise<IRespondent[]> => {
  return fetch(
    `${getAPIBaseUrl()}/api/surveys/${surveyId}/respondents`,
    {
      headers: {
        "canary": getCookie("canary") || "",
        "user-id": getCookie("user-id") || "",
      }
    }
  ).then((response) => response.json());
};

export const getSurveyQuestions = (surveyId: string): Promise<IRawQuestion[]> => {
  return fetch(
    `${getAPIBaseUrl()}/api/surveys/${surveyId}/questions`,
    {
      headers: {
        "canary": getCookie("canary") || "",
        "user-id": getCookie("user-id") || "",
        "public": "1"
      }
    }
  ).then((response) => response.json());
};


export const getSurveyAmbassadors = (surveyId: string): Promise<IUser[]> => {
  return fetch(
    `${getAPIBaseUrl()}/api/surveys/${surveyId}/ambassadors`,
    {
      headers: {
        "canary": getCookie("canary") || "",
        "user-id": getCookie("user-id") || "",
      }
    }
  ).then((response) => response.json());
};

export const getSurveySupervisors = (surveyId: string): Promise<IUser[]> => {
  return fetch(
    `${getAPIBaseUrl()}/api/surveys/${surveyId}/supervisors`,
    {
      headers: {
        "canary": getCookie("canary") || "",
        "user-id": getCookie("user-id") || "",
      }
    }
  ).then((response) => response.json());
};


export const getSurveyAnswers = (surveyId: string): Promise<IRawAnswer[]> => {
  return fetch(
    `${getAPIBaseUrl()}/api/surveys/${surveyId}/answers`,
    {
      headers: {
        "canary": getCookie("canary") || "",
        "user-id": getCookie("user-id") || ""
      }
    }
  ).then((response) => response.json());
};
