import { useEffect, useState } from 'react';
import { Button, NavDropdown } from 'react-bootstrap';
import { IRespondent } from '../../models/IRespondent';
import { IUser } from '../../models/IUser';
import { addSurveySupervisor, getSurveySupervisors, getSurveyRespondents, getUsers, removeSurveySupervisor } from '../../services/api';
import { isAdmin } from '../../utils/ref-util';
import './add-survey-supervisors.scss';

export const AddSurveySupervisors = (props: {
    surveyId: string
}) => {
    const [supervisors, setSupervisors] = useState<IUser[]>([]);
    const [respondents, setRespondents] = useState<IRespondent[]>([]);
    const [surveySupervisors, setSurveySupervisors] = useState<IUser[] | null>(null);
    const [activeSupervisorId, setActiveAmbassadorId] = useState<string>();
    const [error, setError] = useState<string>("");
    const getAllUsers = async (value) => {
        var result = await getUsers(value);
        setSupervisors(result);
    };

    const getSupervisorsAndRespondents = async () => {
        var result1 = await getSurveySupervisors(props.surveyId);
        setSurveySupervisors(result1);
        var results2 = await getSurveyRespondents(props.surveyId)
        setRespondents(results2);
    }

    useEffect(() => {
        getAllUsers('supervisor');
        getSupervisorsAndRespondents();
    }, []);

    const isUserAdmin = isAdmin();

    return (
        <div className="add-survey-supervisors">
            <div>
                <span style={{marginRight:10}}>Asignar supervisor:</span>
                <select style={{marginRight:5, marginBottom:5}}
                    onChange={(e) => { setActiveAmbassadorId(e.target.value)}}
                >
                    <option value="">Selecciona</option>
                    {supervisors?.map((entry, index) => {
                        return <option key={entry.id} value={entry.id}>{entry.firstName ? `(${entry.username}) - ${entry.firstName} ${entry.lastName}`: entry.username}</option>
                    })}
                </select>
                <Button className="add-supervisor-button" variant="primary" type="submit" onClick={(ev) =>{
                    setError('');
                    addSurveySupervisor(props.surveyId, activeSupervisorId || "").then((result) => {
                        if (result === 'Success') {
                            getSupervisorsAndRespondents();
                        } else {
                            console.log(result);
                            setError("No pudimos asignar ese embajador")
                        }
                    })
                }}>Asignar</Button>
                <div className="add-supervisor-error">{error}</div>
            </div>
            {surveySupervisors && surveySupervisors?.length === 0 ? <div style={{marginTop:40}}>Ningun supervisor a sido asignado</div> : <></>}
            {surveySupervisors && surveySupervisors?.length > 0 ? <><table className="supervisor-state-stats">
     
                
            </table><div className="supervisor-state-stats-mobile-legend"></div></> : <></>}
            <div className="supervisors-assigned">
                <br/><br/>
                {surveySupervisors?.map((entry, index) => (
                    <div key={`u-${entry.id}`} className="user-item">
                        <div style={{fontWeight:'bold'}}>
                            {entry.firstName ? `${entry.firstName} ${entry.lastName} (${entry.username})` : entry.username}
                        </div>
                        {entry.companyName ? <div>Company: {entry.companyName}</div> : <></>}
                        {entry.email ? <div>Email: {entry.email}</div>: <></>}
                        <div className="user-item-actions">
                            <a href="#" onClick={() => {
                                setActiveAmbassadorId(entry.supervisorId || "");
                                // TODO: show delete modal
                                removeSurveySupervisor(entry.id || "").then(() => {
                                    getSupervisorsAndRespondents();
                                });
                            }}>Eliminar</a>
                        </div>
                        <br/>
                    </div>))}
            </div>
        </div>
    );
}