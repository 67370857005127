import { getCookie } from "./cookie-manager";

export const isSupervisor = () => {
    var ref = getCookie('ref');
    if (ref === "411") {
        return true;
    }
    return false;
}

export const isAdmin = () => {
    var ref = getCookie('ref');
    if (ref === "311") {
        return true;
    }
    return false;
}

export const isClient = () => {
    var ref = getCookie('ref');
    if (ref === "111") {
        return true;
    }
    return false;
}

export const getCurrentUserId = () => {
    var ref = getCookie('user-id') ? getCookie<string>('user-id') :  "";
    return ref;
}