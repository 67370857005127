import { deleteCookie, getCookie, setCookie } from "./cookie-manager"

export const isLoggedIn = () => {
    return getCookie("canary") && getCookie("user-id");
}

export const handleLogin = (userId: number, username: string, token: string, ref: number) => {
    setCookie('canary', token);
    setCookie('username', username);
    setCookie('user-id', userId.toString())
    setCookie('ref', ref.toString());
    document.location.reload();
}

export const logOut = () => {
    deleteCookie('canary');
    deleteCookie('user-id');
    deleteCookie('username');
    deleteCookie('ref');
    setTimeout(() => {
        document.location.href = "/"
    }, 1000);
}