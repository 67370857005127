import { IRespondent } from "../models/IRespondent";
import { IUser } from "../models/IUser";

export const getEstado = (status: string) => {
    switch(status) {
        case 'Pending':
            return 'Petición';
        case 'Verified':
            return 'Verificado';
        case 'AnsweredSurvey':
            return 'Procesado';
        case 'ReadyForGift':
            return 'Finalizado'
        default:
            return '';
    }
}

export const getStatusPerAmbassador = (ambassadors: IUser[], respondents: IRespondent[]) => {
    var map: {[key: string]: number[]} = {};
    for (var i = 0; i < ambassadors.length; i++) {
        if (!map[ambassadors[i].username || '']) {
            map[ambassadors[i].username || '']= [0, 0, 0, 0];
        }
    }
    for (var i = 0; i < respondents.length; i++) {
        var respondent = respondents[i];
        var username = respondent.ambassadorUsername || '';
        if (!map[username]) {
            map[username]= [0, 0, 0, 0];
        }
        if (respondent.status === "ReadyForGift") {
            map[username][0] = map[username][0] + 1;
            map[username][1] = map[username][1] + 1;
            map[username][2] = map[username][2] + 1;
            map[username][3] = map[username][3] + 1;
        } else if (respondent.status === "AnsweredSurvey") {
            map[username][0] = map[username][0] + 1;
            map[username][1] = map[username][1] + 1;
            map[username][2] = map[username][2] + 1;
        } else if (respondent.status === "Verified") {
            map[username][0] = map[username][0] + 1;
            map[username][1] = map[username][1] + 1;
        }  else if (respondent.status === "Pending") {
            map[username][0] = map[username][0] + 1;
        } 
    }
    var result: { ambassadorId: string, pending: number, verified: number, answeredSurvey: number, readyForGift: number}[] = [];
    Object.keys(map).forEach((key) => {
        result.push({
            ambassadorId: key,
            pending: map[key][0],
            verified: map[key][1],
            answeredSurvey: map[key][2],
            readyForGift: map[key][3]
        })
    });
    return result;
}