import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { AddSurveyAmbassadors } from "../../components/add-survey-ambassadors/add-survey-ambassadors";
import { ISurvey } from "../../models/ISurvey";
import { getSurvey } from "../../services/api";
import { defaultSurveyPhotoUrl } from "../../utils/url-utils";
import './suvery-respondents-page.scss';

export const SurveyRespondentsPage  = () => {
    let { surveyId } = useParams<{ surveyId: string }>();
    const [survey, setSurvey] = useState<ISurvey>();

    useEffect(() => {
        if (surveyId) {
            (async () => {
                var survey = (await getSurvey(surveyId))[0];
                setSurvey(survey);
            })();
        }
    },[]);
    
    return (
        <div className="survey-respondents-page">
            <div className="survey-details">
                <div style={{display:'flex'}}>
                    <img style={{maxHeight:34, marginRight:10}} src={survey?.photoUrl || (survey && defaultSurveyPhotoUrl)}/>
                    <span>{survey?.name}</span>
                </div>
            </div><br/>
            <AddSurveyAmbassadors surveyId={surveyId}/>
        </div>
    );
}