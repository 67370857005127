import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { IQuestion } from "../../models/IQuestion";
import { insertSurveyQuestionAnswerOption, insertSurveyQuestionSubquestion, removeSurveyQuestion, removeSurveyQuestionAnswerOption, removeSurveyQuestionSubquestion } from "../../services/api";
import { getQuestionTypeLabel } from "../../utils/questions-util";
import { AddIcon } from "../add-icon/add-icon";
import { ConfirmDeleteSurveyQuestionModal } from "../confirm-delete-question-modal/confirm-delete-question-modal";
import { RemoveIcon } from "../remove-icon/remove-icon";
import './survey-question-edit-answers.scss';

export interface ISurveyQuestionProps {
    question: IQuestion
    onAnswerChanged: (config: {
        type: string,
        isSet: boolean,
        questionId: string, 
        subquestionId: string, 
        answerId: string
    }) => void
    onQuestionDeleted: () => void;
    onQuestionAnswerChanged: () => void;
}

export const SurveyQuestionEditAnswers = (props: ISurveyQuestionProps) => {
    const [showAddAnswerForm, setShowAddAnswerForm] = useState<boolean>(false);
    const [showAddTablePart, setShowAddTablePart] = useState<boolean>(false);
    const [showAddListPart, setShowAddListPart] = useState<boolean>(false);
    const [showDeleteQuestionConfirm, setShowDeleteQuestionConfirm] = useState<boolean>(false);
    const [activeQuestion, setActiveQuestion] = useState<string>('');
    
    const [text, setText] = useState<string>('')
    const [error, setError] = useState<string>('')
    const question = props.question;
    const isSingleChoice = question.answerType === "singlechoice";
    const [activeTableAdd, setActiveTableAdd] = useState<'row' | 'column'>('column');
    const isMultipleChoice = question.answerType === "multiplechoice";
    const isTable = question.answerType === "table";
    const isText = question.answerType === "text";
    const isDropdown = question.answerType === "dropdown";
    var results: {questionId: string, answerId: string}[] = [];

    return (
        <>
            <div className={"survey-question-edit"}>
                <div style={{float: 'left', marginRight: 10}}>{`[${getQuestionTypeLabel(question)}] ${question.question}`}</div>
                <br/>
                <div style={{clear:'both', display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
                    <div className={"remove-icon-wrapper"}  
                        onClick={() => {
                            setActiveQuestion(question.question);
                            setShowDeleteQuestionConfirm(true);
                        }}
                        style={{cursor: 'pointer', float: 'left', marginRight: 10, color: '#33ACE1'}}>
                        <RemoveIcon></RemoveIcon>&nbsp;eliminar pregunta
                    </div>
                    {isMultipleChoice || isSingleChoice ? (<div style={{cursor: 'pointer', color: '#33ACE1'}} 
                        onClick={() => { setShowAddAnswerForm(true) }}>
                        <AddIcon size={24}></AddIcon>&nbsp;agregar respuesta
                    </div>) : <></>}
                    {isTable ? <div style={{cursor: 'pointer', color: '#33ACE1'}} 
                        onClick={() => { setActiveTableAdd('column'); setShowAddTablePart(true) }}>
                        <AddIcon size={24}></AddIcon>&nbsp;tabla columna
                    </div>: <></>}
                    {isTable ? <div style={{cursor: 'pointer', color: '#33ACE1'}} 
                        onClick={() => { setActiveTableAdd('row'); setShowAddTablePart(true) }}>
                        <AddIcon size={24}></AddIcon>&nbsp;tabla fila
                    </div>: <></>}
                    {isDropdown ? <div style={{cursor: 'pointer', color: '#33ACE1'}} 
                        onClick={() => { setShowAddListPart(true) }}>
                        <AddIcon size={24}></AddIcon>&nbsp;agregar lista opción
                    </div> : <></>}
                </div>
                {
                    showAddListPart ?
                    <div>
                        <div>
                            <label>Lista opción:</label><br/>
                            <input  
                                className="add-option-row"
                                onChange={(e) =>{ setText(e.target.value)} }
                                onBlur={(e) => { setText(e.target.value) } }
                            ></input>
                        </div>
                        <br/>
                        <Button variant="primary" type="submit" onClick={(ev) => {
                            if (!text) {
                                setError('Introduce la opción.');
                                ev.preventDefault();
                                return;
                            }
                            insertSurveyQuestionAnswerOption({
                                questionId: props.question.id,
                                surveyId: props.question.surveyId,
                                answer: text
                              }).then(() => {
                                props.onQuestionAnswerChanged()
                                setShowAddListPart(false);
                            });
                            ev.preventDefault();
                        }}>{'Agregar'}</Button>
                        <Button style={{marginLeft:15}} type="submit" onClick={() => {
                            setError('');
                            setShowAddListPart(false);
                        }}>Cancelar</Button>
                        <br/><br/>
                        <div className="add-answer-error">{error}</div><br/>
                    </div> : <></>
                }
                {
                    showAddAnswerForm && 
                    <div>
                        <div>
                            <label>Respuesta:</label><br/>
                            <input  
                                className="add-option-row"
                                onChange={(e) =>{ setText(e.target.value)} }
                                onBlur={(e) => { setText(e.target.value) } }
                            ></input>
                        </div>
                        <br/>
                        <Button variant="primary" type="submit" onClick={(ev) => {
                            if (!text) {
                                setError('Introduce una respuesta.');
                                ev.preventDefault();
                                return;
                            }
                            insertSurveyQuestionAnswerOption({
                                questionId: props.question.id,
                                surveyId: props.question.surveyId,
                                answer: text
                              }).then(() => {
                                props.onQuestionAnswerChanged()
                                setShowAddAnswerForm(false);
                            });
                            ev.preventDefault();
                        }}>{'Agregar'}</Button>
                        <Button style={{marginLeft:15}} type="submit" onClick={() => {
                            setError('');
                            setShowAddAnswerForm(false);
                        }}>Cancelar</Button>
                        <br/><br/>
                        <div className="add-answer-error">{error}</div><br/>
                    </div>
                }
                {
                    showAddTablePart && 
                    <div>
                        <div>
                            <label>{activeTableAdd === 'column' ? 'Columna texto:': 'Fila texto:'}</label><br/>
                            <input  
                                className="add-option-row"
                                onChange={(e) =>{ setText(e.target.value)} }
                                onBlur={(e) => { setText(e.target.value) } }
                            ></input>
                        </div>
                        <br/>
                        <Button variant="primary" type="submit" onClick={(ev) => {
                            const isAddRow =  activeTableAdd === 'row';
                            if (!text) {
                                var partName = isAddRow ? 'fila': 'columna';
                                setError(`Introduce texto para la ${partName}.`);
                                ev.preventDefault();
                                return;
                            }
                            if (isAddRow) {
                                insertSurveyQuestionSubquestion({
                                    questionId: props.question.id,
                                    surveyId: props.question.surveyId,
                                    text: text
                                }).then(() => {
                                    props.onQuestionAnswerChanged()
                                    setShowAddTablePart(false);
                                });
                            } else {
                                insertSurveyQuestionAnswerOption({
                                    questionId: props.question.id,
                                    surveyId: props.question.surveyId,
                                    answer: text
                                }).then(() => {
                                    props.onQuestionAnswerChanged()
                                    setShowAddTablePart(false);
                                });
                            }
                            ev.preventDefault();
                        }}>{'Agregar'}</Button>
                        <Button style={{marginLeft:15}} type="submit" onClick={() => {
                            setError('');
                            setShowAddTablePart(false);
                        }}>Cancelar</Button>
                        <br/><br/>
                        <div className="add-answer-error">{error}</div><br/>
                    </div>
                }
            </div>
            {
                isSingleChoice || isDropdown || isTable && question.answers ?
                <div className={"survey-question-edit-answer"}>
                    {isTable && <div style={{marginTop:15}}><i>Columnas:</i></div>}
                    {question.answers?.map((entry, index) => (
                        <>
                        <div style={{float: 'left', marginRight: 10}}>
                        <Form.Check
                            onChange={(e) => {
                                props.onAnswerChanged({
                                    type: question.answerType,
                                    isSet: true,
                                    questionId: question.id,
                                    subquestionId: '',
                                    answerId: e.target.value
                                });
                            }}
                            type="radio"
                            label={entry}
                            value={question.answersId?.[index]}
                            name={`radio${question.id}`}
                            id={`radio${question.id}${index}`}
                        /> 
                        </div>
                        <div className={"remove-icon-wrapper"} 
                            onClick={() => {
                                removeSurveyQuestionAnswerOption(question.answersId?.[index] || "").then(() => {
                                    props.onQuestionAnswerChanged();
                                });
                            }}
                            style={{float: 'left',  paddingTop:9}}>
                            <RemoveIcon></RemoveIcon>
                        </div>
                        <div style={{clear: 'both'}}></div>
                        </>
                    ))}
                </div>
                : <></>
            }
            {
                isMultipleChoice ?
                <div className={"survey-question-edit-answer"}>
                    {question.answers?.map((entry, index) => (
                        <>
                        <div style={{float: 'left', marginRight: 10}}>
                        <Form.Check
                            type="checkbox"
                            label={entry}
                            onChange={(e) => {
                                props.onAnswerChanged({
                                    type: question.answerType,
                                    isSet: e.target.checked,
                                    questionId: question.id,
                                    subquestionId: '',
                                    answerId: e.target.value
                                });
                            }}
                            value={question.answersId?.[index]}
                            name={`check${question.id}`}
                            id={`check${question.id}${index}`}
                        /> 
                        </div>
                        <div className={"remove-icon-wrapper"} 
                            onClick={() => {
                                removeSurveyQuestionAnswerOption(question.answersId?.[index] || "").then(() => {
                                    props.onQuestionAnswerChanged();
                                });
                            }}
                            style={{paddingTop:10}}>
                            <RemoveIcon></RemoveIcon>
                        </div>
                        <div style={{clear: 'both'}}></div>
                        </>
                    ))}
                </div>
                : <></>
            }
            {
                isDropdown ?
                <div className={"survey-question-edit-answer"}>
                    <div style={{marginTop:20}}><i>Vista previa:</i></div>
                    <select>
                        <option value="-1">Selecciona</option>
                        {question.answers?.map((entry, index) => (
                            <option value="1">{entry}</option>
                        ))}
                    </select>
                </div>
                : <></>
            }
            {
                isTable ?
                <div className={"survey-question-edit-answer"}>
                    <div style={{marginTop:20}}><i>Filas:</i></div>
                    {question.rows?.map((entry, questionIndex) =>  
                        (<>
                            <div style={{float: 'left', marginRight: 10}}>
                                <div style={{marginTop:10}}>{entry}</div>
                            </div>
                            <div className={"remove-icon-wrapper"} 
                                onClick={() => { 
                                    removeSurveyQuestionSubquestion(question.rowsId?.[questionIndex] || '').then(() => {
                                        props.onQuestionDeleted();
                                    });
                                }}
                                style={{float: 'left',  paddingTop:9}}>
                                <RemoveIcon></RemoveIcon>
                            </div>
                            <div style={{clear: 'both'}}></div>
                        </>)
                    )}
                    <div style={{marginTop:20}}><i>Vista previa:</i></div>
                    <table className="survey-table">
                        <tr>
                            <th></th>
                            {question.answers?.map((entry, index) => (
                                <th>{entry}</th>
                            ))}
                        </tr>
                        {question.rows?.map((entry, questionIndex) => (
                        <tr>
                            <td style={{paddingLeft:4, paddingRight:10}}>{entry}</td>
                            {question.answers?.map((entry, answerIndex) => (
                            <td>
                            <Form.Check
                                type="radio"
                                label={''}
                                value={question.answersId?.[answerIndex]}
                                name={`radio${question.id}-${questionIndex}`}
                                id={`radio${question.id}${answerIndex}`}
                            /> 
                            </td>
                            ))}
                        </tr>
                        ))}
                    </table>
                </div>
                : <></>
            }
            <br/>
            <ConfirmDeleteSurveyQuestionModal 
                question={activeQuestion}
                show={showDeleteQuestionConfirm}
                onAccept={() => {
                    removeSurveyQuestion(question.id).then(() => {
                        props.onQuestionDeleted();
                        setShowDeleteQuestionConfirm(false);
                    });
                }}  
                onHide={() => {
                    setShowDeleteQuestionConfirm(false);
                }}/>
        </>
    );
}


