import { t, Trans } from '@lingui/macro';
import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
// import { RootState } from '../../index.reducers';
import LogoImg from '../../images/logo-tall.png';
import './login.scss';

export const Login = (props: {
        onSubmit: (username: string, password: string) => void,
        error: string
    }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    return (
        <div className='login-page'>
            <div className='login-form'>
                <div className='login-parts'>
                <img
                    src={LogoImg}
                    className="d-inline-block align-top"
                    alt="Encuestas Store"
                />
                <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Usuario</Form.Label>
                        <Form.Control type="text" placeholder="Introduce tu usuario" onChange={e => { 
                            const val = (e.currentTarget as HTMLInputElement).value.trim() ?? '';
                            const isInvalid = val.length > 50;
                            if (!isInvalid) {
                                setUsername(val);
                            }
                        }} />
                        {/* <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                        </Form.Text> */}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Contraseña</Form.Label>
                        <Form.Control type="password" placeholder="Contraseña" onChange={e => { 
                            const val = (e.currentTarget as HTMLInputElement).value.trim() ?? '';
                            const isInvalid = val.length > 50;
                            if (!isInvalid) {
                                setPassword(val);
                            }
                        }} />
                    </Form.Group>
                    {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="Recuerdame" />
                    </Form.Group> */}
                    <Button variant="primary" type="submit" onClick={(ev) => {
                        ev.preventDefault();
                        props.onSubmit(username, password)
                    }}>
                        Iniciar sesión
                    </Button>
                    <br/><br/>
                    <div className="login-error">{props.error}</div>
                    </Form>
                </div>
            </div>
        </div>
    );
};