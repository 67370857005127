import { useLocalStorage, writeStorage } from '@rehooks/local-storage';
import { defaultLocale, getBrowserLanguage } from '../config/locales';

export default function useLocale(
): [string, (locale: string) => void] {
  var defaultLocaleNormalized = getBrowserLanguage() || defaultLocale;
  let [locale] = useLocalStorage<string>('locale', defaultLocaleNormalized);

  if (locale && locale.length === 2) {
    locale = defaultLocaleNormalized;
  }

  function handleSetLocale(locale: string) {
    writeStorage('locale', locale);
  }

  return [locale, handleSetLocale];
}