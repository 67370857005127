import { useEffect, useState } from 'react';
import { Button, Tab, Tabs, Form } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import { AddSurveyAmbassadors } from '../../components/add-survey-ambassadors/add-survey-ambassadors';
import { AddSurveySupervisors } from '../../components/add-survey-supervisors/add-survey-supervisors';
import { AddSurveyQuestions } from '../../components/add-survey-questions/add-survey-questions';
import { ISurvey } from '../../models/ISurvey';
import { IUser } from '../../models/IUser';
import { createSurvey, getSurvey, getUsers, updateSurvey } from '../../services/api';
import { getCookie } from '../../utils/cookie-manager';
import { getCurrentUserId, isAdmin } from '../../utils/ref-util';
import { SurveyResultsPage } from '../survey-results-page/survey-results-page';
import UploadImageToS3 from '../../utils/upload-image-to-S3';
import './edit-survey-page.scss';

export const EditSurveyPage = () => {

    if (!isAdmin()) {
        document.location.href= "/";
    }
    let { surveyId } = useParams<{ surveyId: string }>();
    const [survey, setSurvey] = useState<ISurvey>();
    const [clients, setClients] = useState<IUser[]>();
    const history = useHistory();
    const [error, setError] = useState('');
    const [name, setName] = useState('');
    const [countryCode, setCountryCode] = useState<string>("57");
    const [description, setDescription] = useState('');
    const [ownerId, setOwnerId] = useState('')
    const [photoUrl, setPhotoUrl] = useState('');
    const [welcomeMessage, setWelcomeMessage] = useState(!surveyId ? 'El Centro Comercial « X » se encuentra realizando un Estudio de Mercado. Por anticipado, le agradece su participación en esta encuesta. Responder a las preguntas no le tomará más de tres (3) minutos': '');
    const [giftDetails, setGiftDetails] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [location, setLocation] = useState('');
    const [requestEmail, setRequestEmail] = useState(true);
    const [requestGovernmentId, setRequestGovernmentId] = useState(true);
    const [requestName, setRequestName] = useState(true);
    const [requestPhone, setRequestPhone] = useState(true);

    useEffect(() => {
        if (surveyId) {
            (async () => {
                var survey = (await getSurvey(surveyId))[0];
                setSurvey(survey);
                setName(survey.name);
                setDescription(survey.description || "");
                setPhotoUrl(survey.photoUrl || "");
                setWelcomeMessage(survey.welcomeMessage || "");
                setGiftDetails(survey.giftDetails || "");
                setOwnerId(survey.ownerId || "");
                survey.startDate && setStartDate(new Date(survey.startDate).toISOString().slice(0, 10));
                survey.endDate && setEndDate(new Date(survey.endDate).toISOString().slice(0, 10));
                setLocation(survey.location || "");
                setRequestEmail(!!survey.requestRespondentEmail);
                setRequestGovernmentId(!!survey.requestRespondentGovernmentId);
                setRequestName(!!survey.requestRespondentName);
                setRequestPhone(!!survey.requestRespondentPhone);
                setCountryCode(survey.defaultCountryCode || '57');
            })();
        }
        (async () => {
            var clientsList = (await getUsers('client'));
            setClients(clientsList);
        })();
    }, []);

    const submit = () => {
        if (!name) {
            setError("Introduzca el nombre de la encuesta")
            return;
        }

        var survey: ISurvey = {
            name: name,
            creatorId: getCookie('user-id') || "0"
        }

        if (surveyId) {
            survey.id = surveyId;
        }

        if (description || surveyId) {
            survey.description = description;
        }
        if (photoUrl || surveyId) {
            survey.photoUrl = photoUrl;
        }
        if (welcomeMessage || surveyId) {
            survey.welcomeMessage = welcomeMessage;
        }
        if (giftDetails || surveyId) {
            survey.giftDetails = giftDetails;
        }
        if (startDate) {
            survey.startDate = startDate;
        }
        if (endDate) {
            survey.endDate = endDate;
        }
        if (location || surveyId) {
            survey.location = location;
        }
        
        survey.ownerId = ownerId;

        survey.requestRespondentEmail = requestEmail;
        survey.requestRespondentGovernmentId = requestGovernmentId;
        survey.requestRespondentName = requestName;
        survey.requestRespondentPhone = requestPhone;
        survey.defaultCountryCode = countryCode;
        
        if (!ownerId) {
            if (isAdmin()) {
                setError('Asigne la encuesta a un cliente');
                return;
            } else {
                survey.ownerId = getCurrentUserId();
            }
        }

        if (surveyId) {
            updateSurvey(survey).then((resp: string) => {
                history.push("/");
            });
        } else {
            createSurvey(survey).then((resp: { code: string, surveyId: number}) => {
                if (resp.code === 'Success') {
                    setError('');
                    history.push(`/`)
                } else {
                    setError('No pudimos crear la encuesta. Contacta tu administrador de cuentas');
                }
            });
        }
    };
    return (
        <div className="edit-survey-page">
            <Tabs defaultActiveKey={"information"} onSelect={(value) => { }}>
                <Tab eventKey="information" title={'Información'}>
                    <div className="edit-survey-page-tab">
                        <div className="edit-survey-row">
                            <label>Nombre</label><br/>
                            <input defaultValue={name} type="text" className="survey-name" 
                                onChange={(e) => setName(e.target.value)} 
                                onBlur={(e) => setName(e.target.value)} 
                            />
                        </div>
                        <div className="edit-survey-row">
                            <label>Descripción</label><br/>
                            <textarea rows={2} defaultValue={description}
                                className="survey-description" 
                                onChange={(e) => setDescription(e.target.value)}
                                onBlur={(e) => setDescription(e.target.value)}
                            ></textarea>
                        </div>
                        {<div className="edit-survey-row">
                            <label>Cliente</label><br/>
                            <select aria-label="Seleccciona" 
                                value={ownerId}
                                onChange={(e) => { setOwnerId(e.target.value)}}>
                            <option value="">Selecciona</option>
                            {clients?.map((entry, index) => 
                                <option key={entry.id} value={entry.id}>{entry.username}</option>
                            )}
                        </select>
                        </div>}
                        <div className="edit-survey-row">
                            <label>Foto Url</label><br/>
                            <UploadImageToS3 onSuccess={(url: string) => {
                                 setPhotoUrl(url);
                             }}/>
                            <input 
                                type="text" 
                                placeholder="ej: https://www.titanplaza.com/images/logo_titan_plaza.png"
                                className="survey-name"
                                defaultValue={photoUrl}
                                onBlur={(e) => setPhotoUrl(e.target.value)}
                                onChange={(e) => setPhotoUrl(e.target.value)}/><br/>
                            {photoUrl ? <><br/><img onLoad={() => setError('')} onError={() => setError('Foto Url no se pudo cargar. Verifica el Url')} style={{maxHeight:80}} src={photoUrl}/></>: <></>}
                        </div>
                        <div className="edit-survey-row">
                            <label>Mensaje de bienvenida</label><br/>
                            <textarea 
                                rows={4}
                                defaultValue={welcomeMessage}
                                className="survey-welcome"
                                onChange={(e) => setWelcomeMessage(e.target.value)}
                                onBlur={(e) => setWelcomeMessage(e.target.value)}
                            ></textarea>
                        </div>
                        <div className="edit-survey-row">
                            <label>Detalles del obsequio</label><br/>
                            <textarea rows={4} className="survey-gift" defaultValue={giftDetails} 
                                onChange={(e) => setGiftDetails(e.target.value)}
                                onBlur={(e) => setGiftDetails(e.target.value)}
                            ></textarea>
                        </div>
                        <div className="edit-survey-row">
                            <label>Fecha de inicio</label><br/>
                            <input defaultValue={startDate} type="date" 
                                onChange={(e) => setStartDate(e.target.value)}
                                onBlur={(e) => setStartDate(e.target.value)}
                            ></input>
                        </div>
                        <div className="edit-survey-row">
                            <label>Fecha de finalización</label><br/>
                            <input defaultValue={endDate} type="date"  
                                onChange={(e) => setEndDate(e.target.value)}
                                onBlur={(e) => setEndDate(e.target.value)}
                            ></input>
                        </div>
                        <div className="edit-survey-row">
                            <label>Ubicación</label><br/>
                            <input defaultValue={location} type="text" className="survey-location"  
                                onChange={(e) => setLocation(e.target.value)}
                                onBlur={(e) => setLocation(e.target.value)}
                            />
                        </div>
                        <div className="edit-survey-row">
                            <label>Pais de preferencia:</label><br/>
                            <select value={countryCode} onChange={(ev) => {
                                setCountryCode(ev.target.value);
                            }} style={{minWidth: 120, float: 'left', marginRight: 5}}>
                                <option value="57">+57 - Colombia</option>
                                <option value="52">+52 - Mexico</option>
                                <option value="1">+1 - USA</option>
                            </select>
                            <br/>
                        </div>
                        <div className="edit-survey-respondent-name">
                            <Form.Check
                                type="checkbox"
                                label={"Requerir teléfono"}
                                onChange={(e) => {
                                    setRequestPhone(e.target.checked)
                                }}
                                checked={requestPhone}
                            /> 
                        </div>
                        <div className="edit-survey-respondent-name">
                            <Form.Check
                                type="checkbox"
                                label={"Requerir email"}
                                onChange={(e) => {
                                    setRequestEmail(e.target.checked)
                                }}
                                checked={requestEmail}
                            /> 
                        </div>
                        <div className="edit-survey-respondent-governmentId">
                            <Form.Check
                                type="checkbox"
                                label={"Requerir C.C"}
                                onChange={(e) => {
                                    setRequestGovernmentId(e.target.checked)
                                }}
                                checked={requestGovernmentId}
                            /> 
                        </div>
                        <div className="edit-survey-respondent-name">
                            <Form.Check
                                type="checkbox"
                                label={"Requerir name"}
                                onChange={(e) => {
                                    setRequestName(e.target.checked)
                                }}
                                checked={requestName}
                            /> 
                        </div><br/>

                        <div className="edit-survey-error">{error}</div>
                        <Button variant="primary" type="submit" onClick={(ev) => {
                            ev.preventDefault();
                            submit();
                        
                        }}>{surveyId ? 'Guardar' : 'Crear'}</Button>


                        <Button style={{marginLeft:15}} type="submit" onClick={() => { history.push('/')}}>Cancelar</Button>
                    </div>
                </Tab>
                {surveyId ? <Tab eventKey="questions" title={'Preguntas'}>
                    <div className="edit-survey-page-tab">
                        <AddSurveyQuestions surveyId={surveyId}/>
                    </div>
                </Tab> : <></>}
                {surveyId ? <Tab eventKey="ambassadors" title={'Embajadores'}>
                    <div className="edit-survey-page-tab">
                        <AddSurveyAmbassadors surveyId={surveyId}/>
                    </div>
                </Tab> : <></>}
                {surveyId ? <Tab eventKey="supervisors" title={'Supervisores'}>
                    <div className="edit-survey-page-tab">
                        <AddSurveySupervisors surveyId={surveyId}/>
                    </div>
                </Tab> : <></>}
            </Tabs>
        </div>
    );
};