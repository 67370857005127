import { Button, Modal } from "react-bootstrap";

export interface IConfirmDeleteSurveyQuestionModalProps {
    show: boolean;
    question: string;
    onHide: () => void;
    onAccept: () => void;
}

export const ConfirmDeleteSurveyQuestionModal = (props: IConfirmDeleteSurveyQuestionModalProps) => {
    return (
    <Modal className='confirm-delete-question-modal' centered show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton>
            <Modal.Title>Eliminar pregunta</Modal.Title>
        </Modal.Header>

        <Modal.Body className="confirm-delete-question-modal-body">
            <div>
            Estas seguro que quieres eliminar esta pregunta:
            </div>
            <div>{props.question}</div>
        </Modal.Body>

        <Modal.Footer className='confirm-delete-question-modal-footer'>
            <Button  onClick={() => { 
                props.onAccept();
                }}>Eliminar</Button>
        </Modal.Footer>
    </Modal>
    );
}