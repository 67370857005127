import { useEffect, useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { AddIcon } from '../../components/add-icon/add-icon';
import { ConfirmDeleteSurveyModal } from '../../components/confirm-delete-survey-modal/confirm-delete-survey-modal';
import { MenuIcon } from '../../components/menu-icon/menu-icon';
import { ISurvey } from '../../models/ISurvey';
import { deleteSurvey, getSurveys } from '../../services/api';
import { isAdmin, isSupervisor } from '../../utils/ref-util';
import { defaultSurveyPhotoUrl } from '../../utils/url-utils';
import './landing-page.scss';

export const LandingPage = () => {
    const history = useHistory();
    const [surveys, setSurveys] = useState<ISurvey[]>();
    const [activeSurvey, setActiveSurvey] = useState<ISurvey>();
    const [showConfirmDeleteSurveyModal, setShowConfirmDeleteSurveyModal] = useState(false);

    const fetchSurveys = async () => {
        var items = (await getSurveys());
        setSurveys(items);
    };

    const onDeleteSurvey = (surveyId: string) => {
        deleteSurvey(surveyId || "").then((resp: { code: number}) => {
            if (resp.code === 0) {
                fetchSurveys();
            }
        })
    };

    useEffect(() => {
        fetchSurveys();
    }, []);
    
    const isUserAdmin = isAdmin();
    const isUserSupervisor = isSupervisor();

    return (
        <div className="landing-page">
            <br/>
            {isUserAdmin ? <><a href="#" onClick={() => { history.push('/survey/add')}}><AddIcon></AddIcon> <span>Crear encuesta</span></a><br/><br/></> : <></>}
            {
                surveys?.map((entry: ISurvey, index) => {
                    return (
                        <div key={`survey-${index}`} className="survey-item">

                            <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                                <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                                    <div style={{minWidth:60, display:'flex', justifyContent:'center'}}>
                                        <img style={{maxHeight:34,marginRight:10, maxWidth:55}} src={entry?.photoUrl || (entry && defaultSurveyPhotoUrl)}/>
                                    </div> 
                                    <span style={{paddingLeft:10}}>{entry.name}</span>
                                </div>
                                <div className="landing-menu-item">
                                    <NavDropdown title={''} style={{fontSize:24, color: 'silver'}}>
                                        {isUserAdmin && <NavDropdown.Item key="key1" href="#" onClick={() => {
                                            history.push(`/survey/${entry.id}/edit`)
                                        }}>Editar</NavDropdown.Item>}
                                        {isUserAdmin && <NavDropdown.Item key="key2" href="#" onClick={() => {
                                            setActiveSurvey(entry);
                                            setShowConfirmDeleteSurveyModal(true);
                                        }}>Eliminar</NavDropdown.Item>}
                                        {isUserAdmin && <NavDropdown.Item key="key3" href="#" onClick={() => {
                                            history.push(`/survey/${entry.id}/respondents`)
                                        }}>Ver embajadores</NavDropdown.Item>}
                                        {isUserSupervisor && <NavDropdown.Item key="key3" href="#" onClick={() => {
                                            history.push(`/survey/${entry.id}/respondents`)
                                        }}>Ver respuestas</NavDropdown.Item>}
                                        {!isUserSupervisor && <NavDropdown.Item key="key4" href="#" onClick={() => {
                                            history.push(`/survey/${entry.id}/answers`)
                                        }}>Visualizar</NavDropdown.Item>}
                                        {!isUserSupervisor && <NavDropdown.Item key="key5" href="#" onClick={() => {
                                            history.push(`/survey/${entry.id}/answers?getpdf`)
                                        }}>Descargar Pdf</NavDropdown.Item>}
                                        {!isUserSupervisor && <NavDropdown.Item key="key6" href="#" onClick={() => {
                                            history.push(`/survey/${entry.id}/answers?getexcel`)
                                        }}>Descargar Excel</NavDropdown.Item>}
                                    </NavDropdown>

                                </div>
                            </div>
                        </div>                    
                    )
                })
            }
            <ConfirmDeleteSurveyModal 
                survey={activeSurvey}
                show={showConfirmDeleteSurveyModal}
                onAccept={() => {
                    onDeleteSurvey(activeSurvey?.id || "0");
                    setShowConfirmDeleteSurveyModal(false);
                }}  
                onHide={()=> {
                    setShowConfirmDeleteSurveyModal(false);
                }}/>
        </div>
    ); 
};