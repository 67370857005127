import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Bar, BarChart, Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { ISurvey } from "../../models/ISurvey";
import { getSurvey, getSurveyAnswers, getSurveyQuestions } from "../../services/api";
import './survey-results-page.scss';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import jsPDF from "jspdf";
import htmlToPdfmake from 'html-to-pdfmake';
import * as logo from '../../images/logo-tall.png';
import * as XLSX from 'xlsx';
import { getBase64ImageFromURL, s2ab } from "../../utils/download-util";
import { IRawQuestion } from "../../models/IRawQuestion";
import { getQuestionType } from "../../utils/questions-util";
import { defaultSurveyPhotoUrl } from "../../utils/url-utils";

const COLORS = ['#33ACE1', '#00C49F', '#FFBB28', '#FF8042', "purple", "pink", "navy", "black", "gray", "teal", "red", "brown"];

type IQuestionAnswer = {
    text: string;
    subquestionText: string;
    answersId: [],
    answersText: [],
    answersRespondentId: string[],
    answersName: string[],
    answersPhone: [],
    answersEmail: [],
    questionId: string;
    subquestionId: string;
    graph: string;
}

export const SurveyResultsPage = () => {
    const history = useHistory();
    const [answers, setAnswers] = useState<IQuestionAnswer[] | null>(null);
    const [questions, setQuestions] = useState<IRawQuestion[]>([]);
    const [survey, setSurvey] = useState<ISurvey>();
    const [status, setStatus] = useState<string>();
    const [isDownloadPdf, setIsDownloadPdf] = useState<boolean>(document.location.href.indexOf("getpdf") >= 0)
    const [isDownloadExcel, setIsDownloadExcel] = useState<boolean>(document.location.href.indexOf("getexcel") >= 0)
    let { surveyId } = useParams<{ surveyId: string }>();

    useEffect(() => {
        (async () => {

            var survey = (await getSurvey(surveyId))[0];
            setSurvey(survey);

            var surveyQuestionsPromise = getSurveyQuestions(surveyId);
            
            var answers = (await getSurveyAnswers(surveyId));
            var map = {};
            var questionAnswers: IQuestionAnswer[]= [];
            for (let response of answers) {
                var entry = map[`${response.questionId}-${response.subquestionId}`];
                if (!entry) {
                    map[`${response.questionId}-${response.subquestionId}`] = {
                        text: response.text,
                        graph: response.graph,
                        subquestionText: response.subquestionText,
                        answersId: [response.answerId],
                        answersText: [response.answer || response.freeFormText],
                        answersEmail: [response.email],
                        answersName: [response.name],
                        answersPhone: [response.phone],
                        answersRespondentId: [response.respondentId[0]]
                    }
                } else {
                    entry.answersId.push(response.answerId);
                    entry.answersText.push(response.answer || response.freeFormText);
                    entry.answersEmail.push(response.email);
                    entry.answersName.push(response.name);
                    entry.answersPhone.push(response.phone);
                    entry.answersRespondentId.push(response.respondentId[0]);
                }
            }

            var keys = Object.keys(map);
            for (let key of keys) {
                var entry = map[key];
                questionAnswers.push({
                    questionId: key.split('-')[0],
                    subquestionId: key.split('-')[1],
                    ...entry
                });
            }
            setAnswers(questionAnswers);
            if (isDownloadPdf) {
                setStatus('Descargando PDF...')
                setTimeout(() => {
                    var el = document.getElementById("download-survey-pdf");
                    el && el.click()}
                , 1000);
            }
            if (isDownloadExcel) {
                setStatus('Descargando Excel...')
                var q = await surveyQuestionsPromise;
                setQuestions(q);
                var el = document.getElementById("download-survey-excel");
                el && el.click()
            }
        })();
    }, []);

    return (
        <>
        {answers && answers.length > 0 && <div style={{display:'flex', justifyContent:'start', fontWeight:'bold'}}>{status}</div>}
        {answers && answers.length > 0 ? 
                <div style={{marginTop:20}}>
                    <a href={`/survey/${surveyId}/answers?getpdf`} style={{marginRight:20}}>Descargar PDF</a>
                    <a href={`/survey/${surveyId}/answers?getexcel`}>Descargar Excel</a>
                </div> : <></>
            }
        <div id="survey-results" className="survey-results-page">
            {!isDownloadPdf && <img style={{
                maxHeight:answers && answers.length > 0  ? 80 : 34, 
                marginBottom:5
            }} src={survey?.photoUrl || (survey && defaultSurveyPhotoUrl)} />}
            {answers && answers.length === 0 ? <div>Nadie a tomado le encuesta todavia!</div> : <></>}
            {
            answers && answers.map((entry: IQuestionAnswer, index) => {
                var dataB: Array<{name, value}> = [];
                var dataBOptions: any= {}
    
                for (var i = 0; i < entry.answersId.length; i++) {
                    var val = entry.answersText[i];
                    if (val) {
                        if (!dataBOptions[val]) {
                            dataBOptions[val] = 1;
                        } else {
                            dataBOptions[val] = dataBOptions[val]+1;
                        }
                    }
                }
    
                var options = Object.keys(dataBOptions);
                var values = Object.values<number>(dataBOptions);
                for (i = 0; i < options.length; i++) {
                    dataB.push({
                        name: options[i],
                        value: values[i]
                    });
                }

                const renderLegend = (props) => {
                    const { payload } = props;
                    return (
                      <div style={{marginTop:0, marginLeft:70}}>
                        {
                          payload.map((entry, idx) => (
                            <div style={{display:'flex'}}>
                            <svg className="recharts-surface" width="14" height="14" viewBox="0 0 32 32" version="1.1" style={{marginTop:6, display: 'inline-block', verticalAlign: 'middle', marginRight: 4}}><path stroke="none" fill={COLORS[idx % COLORS.length]} d="M0,4h32v24h-32z" className="recharts-legend-icon"></path></svg>
                            <div><span style={{color:COLORS[idx % COLORS.length]}}>{entry.name}</span>: {entry.value}</div>
                            </div>
                          ))
                        }
                      </div>
                    );
                  }

                return <div key={`q-${entry.questionId}-${entry.subquestionId}`}>
                    <div style={{display:'flex', justifyContent:'start', marginTop:30, paddingBottom:20}}>
                        <div>{`${entry.text} ${entry.subquestionText ?? ''}`}</div>
                    </div>
                    <div style={{display:'flex', justifyContent:'start', marginBottom:50, paddingTop:20}}>
                    {   (entry.graph == "bar" || (!entry.graph && index%2===0)) && <div style={{height:300 + (dataB.length > 6 ? ((dataB.length/6)*100) : 0),width:350}}>
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart width={150} height={40} data={dataB}>
                                <Bar 
                                    isAnimationActive={!isDownloadPdf}
                                    dataKey="value" >
                                        {dataB.map((_, idx) => { return (
                                        <Cell  key={`cell-${index}`} fill={COLORS[idx % COLORS.length]} >

                                        </Cell>
                                        )})}           
                                </Bar>
                                <Legend payload={dataB} content={renderLegend} />
                                <XAxis tick={false} dataKey="name" />
                                <YAxis />
                                {!isDownloadPdf && <Tooltip cursor={{ fill: '#555', fillOpacity: "0.3" }} />}
                            </BarChart>
                        </ResponsiveContainer>
                        </div>}
                    {   
                        (entry.graph == "pie" || (!entry.graph && index%2!==0)) && <PieChart width={350} height={280 + (dataB.length > 3 ? ((dataB.length/3)*100) : 0)}>
                        <Pie
                            label
                            data={dataB}
                            cx={120}
                            cy={Math.max(110, (dataB.length/3)*100)}
                            innerRadius={60}
                            outerRadius={80}
                            fill="#8884d8"
                            paddingAngle={5}
                            dataKey="value"
                            isAnimationActive={!isDownloadPdf}
                            >
                            {dataB.map((_, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Tooltip cursor={{ fill: '#555', fillOpacity: "0.3" }} />
                        <Legend />
                    </PieChart>
                    }
                    {
                        (entry.graph === 'text') && <ul>
                            {dataB.map((entry, index) => {
                                return (<li><q><i>{entry.name}</i></q></li>)
                            })}
                        </ul>
                    }
                    </div>
                </div>
                })}
        </div>
        <button id="download-survey-pdf" style={{color:'black', display:'none'}} onClick={async () => {
            const doc = new jsPDF();
            const pdfTable = document.getElementById('survey-results');
            var html = pdfTable && htmlToPdfmake(pdfTable.innerHTML);
            if (html) {
                const documentDefinition = { 
                    content: html,
                    styles: {
                        li: {listtype: 'none', marginBottom:100}
                    },
                    header: {
                        margin: 10,
                        columns: [
                            {
                                image: await getBase64ImageFromURL(logo.default),
                                width: 50
                            },
                            {
                                margin: [10, 0, 20, 0],
                                text: survey?.name
                            }
                        ]
                    },
                    footer: function (currentPage, pageCount) {
                        return {
                          text: "encuestas.store - page " + currentPage.toString() + ' of ' + pageCount,
                          alignment: 'center',
                          style: 'normalText',
                          margin: [10, 10, 10, 10]
                        };
                      },
                };
                pdfMake.vfs = pdfFonts.pdfMake.vfs;
                pdfMake.createPdf(documentDefinition).open();
                setStatus('');
            }
        }}>Descargar PDF</button>
         <button id="download-survey-excel" style={{color:'black', display:'none'}} onClick={async () => {
                var wb = XLSX.utils.book_new();
                wb.Props = {
                    Title: survey?.name,
                    Subject: "",
                    Author: survey?.createdBy,
                    CreatedDate: new Date()
                };
                var columns = ["Nombre", "Celular", "Email"];
                var responsesMap:  { [key:string]:string[]; } = {};

                if (answers) {
                    for (let answer of answers) {
                        for (let i = 0; i < answer.answersRespondentId.length; i++) {
                            if (!responsesMap[answer.answersRespondentId[i].toString()]) {
                                responsesMap[answer.answersRespondentId[i].toString()] = [
                                    answer.answersName[i],
                                    answer.answersPhone[i] === "-1" ? "": answer.answersPhone[i],
                                    answer.answersEmail[i]
                                ];
                            }
                        }
                    }

                    for (let answer of answers) {
                        var questionType = getQuestionType(questions, answer.questionId, answer.subquestionId);
                        if ((questionType === "singlechoice") ||
                            (questionType === 'table') ||
                            (questionType === 'dropdown') ||
                            (questionType === 'text')) {
                            columns.push(answer.text);

                            for (let i = 0; i < answer.answersRespondentId.length; i++) {
                                var responses = responsesMap[answer.answersRespondentId[i].toString()];
                                responses.push(answer.answersText[i]);
                            }
                        } else if (questionType === "multiplechoice") {
                            var respondents = answer.answersRespondentId;
                            var counts = {};
                            var max = 0;
                            for (let respondentId of respondents) {
                                counts[respondentId] =  1 + (counts[respondentId] || 0);
                                max = Math.max(max, counts[respondentId]);
                            }
                            for (var i = 0; i < max; i++) {
                                columns.push(answer.text + ` opción ${i+1}`);
                            }
                            
                            for (let i = 0; i < answer.answersRespondentId.length; i++) {
                                var responses = responsesMap[answer.answersRespondentId[i].toString()];
                                responses.push(answer.answersText[i]);
                            }

                            // normalize the number of responses on each array
                            var maxColumns = 0;
                            Object.keys(responsesMap).forEach((key: string, index: number) => {
                                maxColumns = Math.max(responsesMap[key].length, maxColumns);
                            });
                            Object.keys(responsesMap).forEach((key: string, index: number) => {
                                var answers =responsesMap[key];
                                var answersLength = answers.length;
                                if (answersLength < maxColumns) {
                                    for (var m = 0; m < maxColumns - answersLength; m++) {
                                        answers.push('');
                                    }
                                }
                            });
                        }
                    }
                }

                var rows = [columns];
                Object.keys(responsesMap).forEach((key: string, index: number) => {
                    var x: string[] = responsesMap[key];
                    rows.push(x);
                });
                wb.SheetNames.push("Test Sheet");
                var ws_data = rows; 
                var ws = XLSX.utils.aoa_to_sheet(ws_data);
                wb.Sheets["Test Sheet"] = ws;
                var wbout = XLSX.writeFileXLSX(wb, "encuesta.xlsx", { bookType:'xlsx', type: 'binary'});
                setStatus('');
            }
        }>Descargar Excel</button>
        </>
    );
}
