import { IQuestion } from "../models/IQuestion";
import { IRawQuestion } from "../models/IRawQuestion";

export const convertRawQuestions = (rawQuestions: IRawQuestion[]) => {
    var questionId = '';
    var subquestionId = '';
    var subquestionsForTable = 0;
    var questions: IQuestion[] = [];
    var index = 0;
    var question: IQuestion | null = null;

    for (var i = 0; i < rawQuestions.length; i++) {
        if (questionId !== rawQuestions[i].questionId) {
            questionId = rawQuestions[i].questionId || "";
            index++;
            question = {
                index: index.toString(),
                surveyId: rawQuestions[i].surveyId,
                id: questionId,
                graph: rawQuestions[i].graph,
                question: rawQuestions[i].text,
                answerType: rawQuestions[i].type,
                answers: (rawQuestions[i].answer ? [rawQuestions[i].answer || ""]: []),
                answersId: (rawQuestions[i].answerId ? [rawQuestions[i].answerId || ""]: []),
            }

            if (question?.answerType === 'table') {
                if (subquestionId !== rawQuestions[i].subquestionId) {
                    subquestionsForTable = 1;
                    subquestionId = rawQuestions[i].subquestionId || "";
                    question.rows = rawQuestions[i].subquestion ? [ rawQuestions[i].subquestion || ""]: []
                    question.rowsId = [ subquestionId ]
                }
            }
            questions.push(question);
        } else {
            // Same question, add answer or row
            
            if (question?.answerType === 'table') {
                if (subquestionId === rawQuestions[i].subquestionId) {
                    if (subquestionsForTable == 1) {
                        question?.answers?.push(rawQuestions[i].answer || "");   
                        question?.answersId?.push(rawQuestions[i].answerId || "");   
                    }           
                }
                if (subquestionId !== rawQuestions[i].subquestionId) {
                    subquestionsForTable++;
                    subquestionId = rawQuestions[i].subquestionId || "";
                    question?.rows?.push(rawQuestions[i].subquestion || "");
                    question?.rowsId?.push(rawQuestions[i].subquestionId || "");
                }
            } else {
                question?.answers?.push(rawQuestions[i].answer || "");
                question?.answersId?.push(rawQuestions[i].answerId || "");
            }
        }
    }
    return questions;
}

export const getQuestionType = (questions: IRawQuestion[], questionId, subquestionId) => {
    var type = '';
    for (let question of questions) {
        if (question.questionId === questionId) {
            if (subquestionId === question.subquestionId ||
                (subquestionId === "0" && !question.subquestionId)) {
                type = question.type;
                break;
            }
        }
    }
    return type;
};

export const getQuestionTypeLabel = (question) => {
    switch(question.answerType) {
        case "table":
            return "Tabla";
        case "dropdown":
            return "Lista desplegable";
        case "singlechoice":
            return "Elección única";
        case "multiplechoice":
            return "Opción multiple";
        case "text":
            return "Texto";
    }
}