import { Trans } from '@lingui/macro';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import './accept-conditions-modal.scss';

export interface IAcceptConditionsModalProps {
    show: boolean;
    countryCode: string;
    onHide: () => void;
    onAccept: () => void;
}

export const AcceptConditionsModal = (props: IAcceptConditionsModalProps) => {
    return <Modal className='accept-conditions-modal' centered show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton>
            <Modal.Title>Condiciones</Modal.Title>
        </Modal.Header>

        <Modal.Body className="accept-conditions-modal-body">
            <div>
            {props.countryCode === '52' ? <>
                    En cumplimiento con lo establecido por la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, le informamos que Encuestas Store, es el responsable del tratamiento y uso de sus datos personales, por lo que lo invitamos a consultar nuestro <a href="/privacy-mx" target='_blank'>aviso de privacidad</a> y/o enviar un de correo electrónico a encuestasstore@gmail.com. Si usted no manifiesta su oposición al presente aviso de privacidad, se entenderá que ha otorgado su consentimiento para ello
                </> :
                <>En cumplimiento con las disposiciones de la Ley 1581 de 2012 y del Decreto reglamentario 1377 de 2013 que desarrollan el derecho de habeas data, solicitamos su autorización de manera libre y expresa para que la entidad ENCUESTAS STORE en su calidad de Responsable del Tratamiento pueda recopilar, almacenar, archivar, copiar, analizar, usar y consultar los datos personales entregados en la presente encuesta. En caso de que usted tenga alguna duda o inquietud, puede escribir al correo electrónico: encuestasstore@gmail.com donde también podrá solicitar el manual de Políticas y Procedimientos Habeas Data</>
            }
            </div>
        </Modal.Body>

        <Modal.Footer className='accept-conditions-footer'>
            <Button  onClick={() => {
                props.onAccept();
                }}>Aceptar</Button>
        </Modal.Footer>
    </Modal>
};
