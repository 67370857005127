import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { IQuestion } from '../../models/IQuestion';
import { IRawQuestion } from '../../models/IRawQuestion';
import { addSurveyQuestion, getSurveyQuestions } from '../../services/api';
import { convertRawQuestions } from '../../utils/questions-util';
import { AddIcon } from '../add-icon/add-icon';
import { SurveyQuestionEditAnswers } from '../survey-question-edit-answers/survey-question-edit-answers';
import './add-survey-questions.scss';

export const AddSurveyQuestions= (props: {
    surveyId?: string
}) => {
    const [questions, setQuestions] = useState<IQuestion[]>([]);
    const [rawQuestions, setRawQuestions] = useState<IRawQuestion[]>([]);
    const [showAddQuestionForm, setShowAddQuestionForm] = useState<boolean>(false);
    const [text, setText] = useState<string>('');
    const [type, setType] = useState<string>('');
    const [graphVisible, setGraphVisible] = useState<boolean>(true);
    const [graph, setGraph] = useState<string>('');
    const [error, setError] = useState<string>('');
    const getQuestions = async () => {
        const rawQuestions = await getSurveyQuestions(props.surveyId || "0");
        setQuestions(convertRawQuestions(rawQuestions));
        setRawQuestions(rawQuestions);
    };

    const onQuestionTypeChanged = (e) => {
        setType(e.target.value);
        if (e.target.value === "text") {
            setGraphVisible(false);
            setGraph('text');
        } else {
            setGraphVisible(true);
            setGraph('');
        }
    }

    useEffect(() => {
        getQuestions();
    }, []);

    return (
        <div className="add-survey-questions-page">
            
            <div style={{cursor: 'pointer', color: '#33ACE1'}} onClick={() => { 
                setShowAddQuestionForm(true);
                setGraphVisible(true);
            }}>
                <AddIcon></AddIcon>&nbsp;agregar pregunta
            </div>
            {
                showAddQuestionForm ? 
                <div>
                    <div>
                        <label>Pregunta:</label><br/>
                        <textarea  
                            rows={3}
                            className="add-question-row"
                            onChange={(e) =>{ setText(e.target.value)} }
                            onBlur={(e) => { setText(e.target.value) } }
                        ></textarea>
                    </div>
                    <div>
                        <br/>
                        <label>Tipo:</label><br/>
                        <select aria-label="Seleccciona" onChange={onQuestionTypeChanged}>
                            <option value="">Selecciona</option>
                            <option value="singlechoice">Elección única</option>

                            {/* <option value="2">Elección única y escala</option> */}
                            <option value="multiplechoice">Opción multiple</option>
                            <option value="text">Texto</option>
                            <option value="table">Tabla</option>
                            <option value="dropdown">Lista desplegable</option>
                        </select>
                    </div>
                    <div style={{display: graphVisible ? 'block': 'none'}}> 
                        <br/>
                        <label>Gráfica:</label><br/>
                        <select onChange={(e) => setGraph(e.target.value)}>
                            <option value="">Selecciona</option>
                            <option value='bar'>Barras</option>
                            <option value='pie'>Torta</option>
                        </select>
                    </div>
                    <br/>
                    <Button variant="primary" type="submit" onClick={(ev) => {
                        if (!text || !type || !graph) {
                            setError('Introduce la pregunta, el tipo de pregunta, y gráfica para continuar.');
                            ev.preventDefault();
                            return;
                        }
                        setShowAddQuestionForm(false);
                        addSurveyQuestion({
                            text: text,
                            type: type,
                            graph: graph,
                            surveyId: props.surveyId || ''
                        }).then(() => {
                            getQuestions();
                        });
                        ev.preventDefault();
                    }}>{'Agregar'}</Button>
                    <Button style={{marginLeft:15}} type="submit" onClick={() => {
                        setError('');
                        setShowAddQuestionForm(false);
                     }}>Cancelar</Button>
                    <br/><br/>
                    <div className="add-question-error">{error}</div><br/>
                </div> :
                <></>
            }

            {questions.map((entry, index) => (
                <>
                <SurveyQuestionEditAnswers 
                    question={entry}
                    onQuestionDeleted={() => {
                      getQuestions();  
                    }}
                    onQuestionAnswerChanged={()=>{
                        getQuestions();  
                    }}
                    onAnswerChanged={(config: {
                        type: string,
                        isSet: boolean,
                        questionId: string, 
                        subquestionId: string, 
                        answerId: string
                    }) => {
                        
                    }}></SurveyQuestionEditAnswers>
                </>
            ))}
            
        </div>
    );
};
